import { useEffect, useState } from "react";

function Version() {
  const getVersion = () => {
    const { REACT_APP_BUILD_INFO } = process.env;
    if (REACT_APP_BUILD_INFO) {
      const build = REACT_APP_BUILD_INFO.split("|");
      if (build && build.length > 1) {
        return `${build[0]} (${build[1]})`;
      }
    }
    return "";
  };
  return <>{getVersion()}</>;
}

function Document({ document }) {
  const { name, description, size, link, version, date } = document;
  return (
    <div className="p-0 pt-6 pb-3 xbg-red-200 w-full flex gap-2">
      <div className="basis-4/5">
        <p className="font-medium text-gray-900">{name}</p>
        <p className="pt-2 text-gray-700">{description}</p>
        <p className="pt-4 text-xs text-gray-500">
          Version: {version}, last updated: {date}
        </p>
      </div>
      <div className="p-2 pt-3 basis-1/5">
        <div>
          <a href={link}>
            <img
              src="adobe-pdf-icon.svg"
              className="m-auto"
              alt={name}
              style={{ maxHeight: "100px" }}
            />
          </a>
        </div>
        <div className="pt-1">
          <p className="text-sm text-center">{size}</p>
        </div>
      </div>
    </div>
  );
}

function ScannerDetails({ details }) {
  console.log("setting details", details);
  return (
    <div className="p-6 py-7 bg-white">
      <div>
        <p className="text-[#36796B] text-xl text-center pb-3">
          Scanner status
        </p>
      </div>
      {details && (
        <div className="bg-[#36796B] w-full p-6 rounded-md text-white">
          <dl>
            <div className="grid grid-cols-3 text-lg">
              <dt className="font-medium py-0">Serial</dt>
              <dd className="col-span-2">{details.serial}</dd>
              <dt className="font-medium py-0">Revision</dt>
              <dd className="col-span-2">{details.revision}</dd>
              <dt className="font-medium py-0">Network</dt>
              <dd className="col-span-2">
                {details.online ? "Online" : "Offline"}
              </dd>
              {details.online && (
                <>
                  <dt className="font-medium py-0">Status</dt>
                  <dd className="col-span-2 capitalize">{details.health}</dd>
                </>
              )}
            </div>
          </dl>
        </div>
      )}
      {!details && (
        <div className="bg-[#36796B] w-full p-6 rounded-md text-white">
          <p>Scanner unknown</p>
        </div>
      )}
    </div>
  );
}

function SupportDetails() {
  return (
    <div className="p-6 py-7 bg-white">
      <div>
        <p className="text-[#36796B] text-xl text-center pb-3">
          Need assistance?
        </p>
      </div>
      <div className="bg-[#36796B] w-full p-6 rounded-md text-white">
        <dl>
          <div className="grid grid-cols-3 text-lg">
            <dt className="font-medium py-0">Email</dt>
            <dd className="col-span-2">
              <a href="mailto:support@manometric.nl">support@manometric.nl</a>
            </dd>
            <dt className="font-medium py-0">Phone</dt>
            <dd className="col-span-2">
              <a href="tel:+31702211625">+31 70 221 16 25</a>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

function Documents() {
  const [documents, setDocuments] = useState([]);
  const [details, setDetails] = useState(null);

  const loadDocuments = (revision) => {
    console.log("loading documents for revision: ", revision);
    fetch(
      `https://manometric-docs.ams3.digitaloceanspaces.com/devices/manox2.0/rev${revision}/documents.json`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setDocuments(result.documents);
        },
        (error) => {
          console.log("error loading documents!", error);
        }
      );
  };

  const loadStatus = () => {
    const parts = window.location.href.split("#");
    const fragment = parts[parts.length - 1];
    const [id, hash] = fragment.split("-");
    if (id && hash) {
      fetch(
        `https://api.manometric.cloud/manox/v0/devices/${id}/status/${hash}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 404) {
            return Promise.reject("scanner not found");
          } else {
            return Promise.reject("unexpected error: " + response.status);
          }
        })
        .then(
          (data) => {
            setDetails(data.result);
            loadDocuments(data.result.revision);
          },
          (error) => {
            console.log("error loading status!", error);
          }
        );
    }
  };

  useEffect(() => {
    window.addEventListener("hashchange", (e) => {
      // Force reload when hash has changed
      window.location.reload();
    });
    if (!details) {
      loadStatus();
    }
  });

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row p-3 bg-gray-200 font-['europa']">
        <div className="flex flex-col w-full justify-center">
          <div className="text-4xl font-medium text-black text-center">
            manoX 2
          </div>
          <p className="text-gray-500 text-lg text-center">Documentation</p>
        </div>
      </div>
      <ScannerDetails details={details} />
      <div className="p-6 h-full flex flex-col gap-2 divide-y divide-slate-300 bg-gray-100">
        {documents.map((d) => (
          <Document document={d} key={d.name} />
        ))}
      </div>
      <SupportDetails />
      <div className="p-3 flex justify-center bg-[#36796B]/10">
        <div className="pt-4 flex flex-col justify-center">
          <div className="">
            <p className="text-xl font-normal text-center font-['europa']">
              The new standard in
              <br />
              orthopedic care.
            </p>
          </div>
          <div className="pt-4 pb-6">
            <a href="https://manometric.nl/?lang=en">
              <img src="logo-black-block.svg" height="30px" alt="logo" />
            </a>
          </div>
          <div>
            <p className="text-center text-xs text-slate-500">
              <Version />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;
